@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&family=Rubik:wght@500;700&display=swap");

/*
 * Scrollbar
 */
::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-track {
  background: #81818179;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb {
  background: rgb(206, 206, 206);
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(255, 255, 255);
}

body {
  @apply bg-stone-900 font-body text-white;
}

.overlay {
  background-image: linear-gradient(
    -66deg,
    rgba(0, 0, 0, 0) 41%,
    rgba(0, 0, 0, 0.86) 85%
  );
}
