.App {
  display: grid;
  grid-template-columns: 24rem auto;
  grid-template-rows: 3rem 1fr;
  grid-template-areas:
    "header header"
    "side panorama";
}

.App.side-open {
  grid-template-columns: 48rem auto;
}

.Header {
  grid-area: header;
}

.Side {
  grid-area: side;
}

.Panorama {
  grid-area: panorama;
}
